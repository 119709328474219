import { Body } from 'components/layout/Body';
import { Container, Grid, GridItem } from 'components/layout/Grid';
import { Main } from 'components/layout/Main';
import { Typography } from 'components/ui/Typography';
import BetMgm_IMAGE from '../../assets/images/BetMGM-Logo-–-HiRes.png';
import connexImage from '../../assets/images/ConnexOntario-tag_Logo.png';
import IGaming from '../../assets/images/logo--print-en.png';
import style from './style.module.scss';
import classNames from 'classnames';
import { Logo } from 'components/ui/Logo';
import { pageUrl } from 'utils/mgm-pages';
import { useLocation } from 'react-router-dom';


function BetMGM() {
	const location = useLocation();
	const signupUrl = pageUrl(location.pathname);

	return (
		<Body className={style.mainBG}>
			<Main>
				<header className={style.headerLogo}>
					<Logo />
				</header>
				<Container className={classNames(style.container, 'ptb-52')}>
					<Grid
						cols={2}
						width={100}
						customProps={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
						className={style.mobile_grid}
					>
						<GridItem>
							<div className={style.imgBG}>
								<img src={BetMgm_IMAGE} alt="BetMgm_IMAGE" />
							</div>
						</GridItem>
						<GridItem>
							<Typography
								component="h2"
								variant="h3"
								weight="bold"
								textTransform="capitalize"
								textAlign="center"
							>
								BETMGM
							</Typography>
							<Typography variant="h5" textAlign={'center'}>
							Ontario's premier site for online casino games. BETMGM offers live tables, top-tier slots, and an unmatched gaming experience!
							</Typography>
							<Typography variant="p1" textAlign={'center'}>
							Bounty has partnered with BETMGM to bring even more FUN to your fingertips. Fully licensed in Ontario, BETMGM offers a world-class selection of casino games, including live tables and top slots. Click below to start playing today!
							</Typography>
							<a
								href={signupUrl}
								className={style.buttonMGM}
							>
								Click to Sign-Up For BETMGM
							</a>
						</GridItem>
					</Grid>
				</Container>
				<Container className={classNames(style.container, 'ptb-52 mt-50')}>
					<Grid
						cols={1}
						width={50}
						customProps={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
						className={style.gridStrech}
					>
						<GridItem className={style.flex_column_para}>
							<div className={style.box}>
								<div className={style.imgBox}>
									<a href="https://connexontario.ca/" rel="noreferrer" target='_blank'>
									<img
										src={connexImage}
										alt="BetMgm_IMAGE"
										height="60px"
									/>
									</a>
								</div>
								<Typography
									variant="p2"
									weight="regular"
									textTransform="capitalize"
									textAlign="center"
									className="mb-30 paraBox"
								>
									ConnexOntario provides service information and
									resources for people experiencing problems with
									gambling, drugs, alcohol, or mental health.The service
									is free, confidential, and available anytime.
								</Typography>
								<Typography
									variant="p2"
									weight="bold"
									textTransform="capitalize"
									textAlign="center"
								>
									Phone: <a href="tel:1-866-531-2600">1-866-531-2600</a>
									<br />
									Email:{' '}
									<a href="mailto:administration@connexontario.ca">
										administration@connexontario.ca
									</a>
								</Typography>
							</div>
						</GridItem>
						{/* <GridItem className={style.flex_column_para}>
							<div className={style.box}>
								<div className={style.imgBox}>
								<a href="https://www.igamingontario.ca/en" rel="noreferrer" target='_blank'>
									<img
										src={IGaming}
										alt="BetMgm_IMAGE"
										height="108px"
									/>
									</a>
								</div>
								<Typography
									variant="p1"
									weight="regular"
									textTransform="capitalize"
									textAlign="center"
									className="mb-30 paraBox"
								>
									iGaming Ontario (iGO) has worked with the Government
									of Ontario and the Alcohol and Gaming Commission of
									Ontario (AGCO) to establish a new online gaming market
									that helps protect consumers gambling through private
									gaming companies (Operators).
								</Typography>
								<Typography
									variant="p2"
									weight="bold"
									textTransform="capitalize"
									textAlign="center"
								>
									Phone: <a href="tel:1-833 554-4263">1-833 554-4263</a>
									<br />
									Email:{' '}
									<a href="mailto:igaming@igamingontario.ca">
										igaming@igamingontario.ca
									</a>
								</Typography>
							</div>
						</GridItem> */}
					</Grid>
					<Grid
						cols={1}
						width={100}
						customProps={{
							alignItems: 'center',
							justifyContent: 'center'
						}}
						className={style.gridStrechBox}
					>
						<GridItem className={style.box}>
							<Typography
								variant="p2"
								weight="bold"
								textTransform="capitalize"
								textAlign="center"
							>
								Must be 19+ and physically present in Ontario to place
								wagers. If you or someone you know has a gambling problem,
								help is available. <a style={{color:"#fff"}} href="https://connexontario.ca/" target='_blank' rel="noreferrer">www.connexontario.ca</a> or call
								ConnexOntario at 1-866-531-2600.
							</Typography>
						</GridItem>
					</Grid>
				</Container>
			</Main>
			<footer className={style.footerClass}>
				<Typography variant2Xl="p1">© 2024 - All Rights Reserved.</Typography>
			</footer>
		</Body>
	);
}

export default BetMGM;
