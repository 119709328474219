import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { checkLocation } from 'services/user.service';

export const useGeolocation = () => {
	const navigate = useNavigate();
    let { pathname } = useLocation();
	useEffect(() => {
		console.log("pathname ",pathname)
		if(pathname != '/mgm' && pathname != "/draftkings" && !pathname.includes("/mgm/")) handleLocation();
	}, [pathname]);

	const handleLocation = async () => {
		try {
			await checkLocation();
		} catch (e: any) {
			if (e?.code !== 'ERR_NETWORK') navigate('/restricted');
		}
	};
};
