export const pageUrl = (routeName:string)=>{
            routeName = routeName.replace(/mgm|\/+/g, "");
            const pages = [
                {route:"7114330",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1709021"},
                {route:"7111061",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1705435"},
                {route:"7113174",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1707753"},
                {route:"7117056",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1712090"},
                {route:"7126278",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730068"},
                {route:"7129203",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730068"},
                {route:"7130536",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730071"},
                {route:"7137153",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730101"},
                {route:"7137227",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730599"},
                {route:"7129599",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1723951"},
                {route:"7131001",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1732168"},
                {route:"7131000",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1725227"},
                {route:"7132541",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1726613"},
                {route:"7132543",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1726615"},
                {route:"7132547",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1726619"},
                {route:"7131911",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1726110"},
                {route:"7131921",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1726113"},
                {route:"7136099",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1729285"},
                {route:"7137316",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730306"},
                {route:"7137320",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1731188"},
                {route:"7137321",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1731233"},
                {route:"7137323",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730313"},
                {route:"7137326",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1732109"},
                {route:"7137328",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730318"},
                {route:"7137329",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1732330"},
                {route:"7137331",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1732110"},
			    {route:"7137335",signupUrl:"https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1730325"}
            ];

            const selectedPage = pages.filter((page:any)=> page.route == routeName)
 
            if(selectedPage.length > 0){
                return selectedPage[0].signupUrl
            }else{
                return "https://mediaserver.betmgmpartners.com/renderBanner.do?zoneId=1707752";
            }
}
